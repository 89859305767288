<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <v-card elevation="2">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>{{ $t('interface.create_password') }}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-text-field
                                    prepend-icon="email"
                                    name="username"
                                    v-model="username"
                                    label="Username"
                                    type="text"
                                    aria-disabled="true"
                                    disabled
                            />
                            <v-text-field
                                    prepend-icon="password"
                                    name="password1"
                                    v-model="password1"
                                    label="New password"
                                    type="password"
                            />
                            <v-text-field
                                    prepend-icon="password"
                                    name="password2"
                                    v-model="password2"
                                    label="Confirm new password"
                                    type="password"
                            />
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="accent"
                               elevation="2"
                               x-large
                               block
                               @click.prevent.once="createPassword"
                        >{{ $t('interface.create_password') }}</v-btn>
                    </v-card-actions>
                </v-card>
                <v-alert
                    :value="show_error"
                    type="error"
                >
                    {{ error }}
                </v-alert>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import axios from 'axios'

export default {
    name: 'create-password',
    data: function () {
        return {
            token: '',
            email: '', 
            username: '', 
            password1: '',
            password2: '',
            show_error: false,
            error: '',
        }
    },
    methods: {
        getInsightsUser () {
            const url = process.env.VUE_APP_API_URL + '/insights/password-create/' + this.$route.params.token
            axios.get(url).then((resp) => {
                this.email = resp.data.user.email
                this.username = resp.data.user.username
            }).catch(err => {
                // Redirect to login if no token is found...
                console.error(err)
                this.$router.push('/')
            })
        },
        createPassword () {
            this.show_error = false;
            if (this.password1 === this.password2) {
                const url = process.env.VUE_APP_API_URL + '/insights/password-create/' + this.$route.params.token
                axios.post(url, {
                    email: this.email,
                    new_password1: this.password1,
                    new_password2: this.password2,
                })
                .then(() => {
                    // Back to login, maybe add a notification that a password has been set up correctly?
                    this.$router.push('/')
                })
                .catch(err => {
                    this.error = "Uh oh, something went wrong.. " + err.message, 
                    this.show_error = true
                })
            } else {
                this.error = "Passwords don't match!"
                this.show_error = true;
            }
        },
    },
    beforeCreate() {
        // If a user is logged in, log that user out by dispatching a logout before creating this component
        if (this.$store.state.token) {
            this.$store.dispatch('logout')
        }
    },
    mounted() {
        this.getInsightsUser()
    },
}
</script>